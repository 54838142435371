import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import SideBarItems from '../../StaticData/json/GlobalSearch'

const state = {
  horizontalMenu: false,
  miniSidebarMenu: false,
  lang: { title: 'English', value: 'en' },
  langOption: [
    { title: 'English', value: 'en' },
    { title: 'Arabic', value: 'ar' },
    { title: 'Chinese', value: 'chi' },
    { title: 'Hindi', value: 'hi' },
    { title: 'Greek', value: 'gr' },
    { title: 'Franch', value: 'fr' }
  ],
  colors: [
    { primary: '#140958' },
    { primary: '#02d871' },
    { primary: '#309cf3' },
    { primary: '#ff00ff' },
    { primary: '#fb1f4c' },
    { primary: '#00ced1' },
    { primary: '#f35f3b' },
    { primary: '#fde117' },
    { primary: '#ff0000' },
    { primary: '#88028c' },
    { primary: '#00d5ab' },
    { primary: '#ce9252' },
    { primary: '#f9ae02' },
    { primary: '#1e7fe4' },
    { primary: '#006400' },
    { primary: '#fa7c04' },
    { primary: '#81bf02' },
    { primary: '#99a7ca' },
    { primary: '#3d2a26' },
    { primary: '#a0746b' }
  ],
  authUser: {
    auth: false,
    authType: false,
    user: {}
  },
  users: [
    {
      id: 'sh46s546sdg564sdffs4hsd6fh',
      name: 'Admin Demo',
      mobileNo: null,
      email: 'admin@demo.com',
      profileImage: null,
      password: 'admin123'
    }
  ],
  globalSearch: SideBarItems,
  bookmark: [
    {
      title: 'Video Chat',
      link: { 'name': 'app.chat' },
      is_icon_class: true,
      icon: 'ri-message-line'
    },
    {
      title: 'Product Listing',
      link: { 'name': 'app.e-commerce.index' },
      is_icon_class: true,
      icon: 'ri-file-list-line'
    },
    {
      title: 'Social App',
      link: { 'name': 'social.list' },
      is_icon_class: true,
      icon: 'ri-question-answer-line'
    },
    {
      title: 'Todo',
      link: { name: 'app.todo' },
      is_icon_class: true,
      icon: 'ri-chat-check-line'
    },
    {
      title: 'Inbox',
      link: { 'name': 'app.email' },
      is_icon_class: true,
      icon: 'ri-inbox-line'
    }

  ],
  activePage: {
    name: 'Dashboard',
    breadCrumb: [
      {
        html: '<i class="ri-home-4-line mr-1 float-left"></i>Home',
        to: { name: 'dashboard.home-1' }
      },
      {
        text: '',
        href: '#'
      }
    ]
  },
  layoutMode: {
    dark: false,
    rtl: false,
    sidebar: ''
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
