<template>
  <router-view/>
</template>
<script>
import { core } from './config/pluginInit'
import Vue from 'vue';
import VueMeta from 'vue-meta';
Vue.use(VueMeta);
export default {
  name: 'App',
  components: {
  },
  metaInfo() {
    return {
      link: [
        { rel: 'icon', href: '/Fevicon_c9eye.png' }
      ]
    };
  },
  mounted () {
    core.mainIndex()
  }
}
</script>
<style lang="scss">
  @import "assets/scss/style.scss";
</style>
